/* ----------  Icons  ---------- */

// Font Awesome
@import '../vendors/icons/@fortawesome/fontawesome-free/css/all.min.css';

// Simple Line Icons
@import '../vendors/icons/simple-line-icons/css/simple-line-icons.css';

// Material Design Icons
@import '../vendors/icons/material-design-iconic-font/dist/css/material-design-iconic-font.css';

// Material Design Icons
@import '../vendors/icons/@mdi/font/css/materialdesignicons.min.css';

// Flag Icon
@import '../vendors/icons/flag-icon-css/css/flag-icon.min.css';

// Themify Icon
@import '../vendors/icons/ti-icons/css/themify-icons.css';

// Weather Icons
@import '../vendors/icons/weather-icons/css/weather-icons.min.css';
